:root {
    --black: #000000;
    --secondary-black: #555454;
    --tableheader-color: #5FBC61;
    --gray: #049457;
    --green: #049457;
    --white: #ffff;
    --table-border-color: #969696;
}

* {
    scrollbar-width: thin;
    scrollbar-color: var(--gray) transparent;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    margin-block: 5px;
    margin-inline: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 100vw;
}

.cg-header {
    background-color: #f0faf5 !important;
}

.cg-header {
    box-shadow: none !important;
    position: fixed !important;
    top: 0 !important;
    z-index: 11;
    left: auto !important;
    right: 0 !important;
}

.ps-sidebar-container {
    z-index: 15 !important;
}

.full-h {
    height: 100%;
}

.primary-bg {
    background-color: #1f8c3a;
}

.light-bg {

    background-color: rgba(95, 188, 97, 0.21);
    height: 700px !important;
}

.light-bg-ext {
    background-color: rgba(233, 236, 239, 0.53);
}

.page {
    padding: 20px;
}

.ps-sidebar-root {
    border: 0px !important
}

.ps-menu-button {
    position: relative;
    transition: all 0.3s ease-in-out;
}

.ps-menu-button>span:first-child {
    color: #1f8c3a;
}

.ps-menu-button>span:last-child {
    color: #1f8c3a;
    font-weight: 600;
}

.ps-menu-button:hover {
    background-color: #1f8c3a !important;
}

.ps-menu-button:hover>span {
    color: #fff !important;
}

.ps-submenu-content.ps-open {
    background-color: #aed4c1 !important;
}

.cg-wrapper {
    display: flex;
    height: 100vh;
    justify-content: flex-start;
}

.cg-content {
    width: 100%;
    height: 100%;
}

.cg-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    z-index: 5;
    /* margin-top: 60px; */
}

.cg-content .cg-layout {
    width: 100%;
    height: 100%;
    padding: 20px;
}

.cg-header {
    box-shadow: none !important;
}

.cg-logo {
    /* direction: ltr;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 64px; */
    position: relative;
    background-color: #ffff;
}

.cg-logo-lg-img {
    height: 60px;
    width: 116px;
}

.cg-logo-lg-leftarrow {
    position: absolute;
    width: 15px;
    height: 15px;
    right: 0;
    top: 70%;
    background-color: #F0FAF5;
    align-items: "center";
}

.cg-logo-lg-leftarrow .rotate {
    transform: rotate(180deg);
}

.cg-logo-mobile-img {
    height: 60px;
    width: 65px;
}

.cg-logo>span {
    margin-left: auto;
}

.box-addmore>li {
    display: flex;
}


/* .cg-pages {
    height: calc(100% - 64px);
    height: 85vh;

}

.cg-pages>div>div {
    height: 85vh !important;
} */

/* .cg-pages .MuiGrid-root {
    height: 100%;
} */


.cg-forms .form-field {
    background-color: #ffff;
    /* height: 65vh;
    width: 650px; */
    width: 600px;
    height: 600px;
    max-height: 65vh;
    overflow-y: auto;
}

.cg-forms .form-field .divider {
    padding: 30px 60px;
}

/* login page styles */

.welcomeHead {
    font-weight: 700 !important;
    font-size: 32px !important;
    line-height: 38px !important;
    color: var(--black);
}

.welcomeSubHead {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    color: var(--secondary-black);
}

.forgotCtr {
    text-align: right;
    padding: 10px 0px !important;
}

.forgotCtr a {
    font-size: 14px;
    line-height: 14px;
    color: #C19393;
    text-decoration: unset;
}

/* roles page design */

.rolesPageTable {
    border: 1px solid var(--table-border-color);
    background: var(--tableheader-color);
    border-radius: 10px;
}

.rolesPageTable table tr td {
    background: var(--white);
    color: var(--black);
}

.rolesPageTable table tbody tr:last-child td {
    border-bottom: none !important;
}

.rolesPageTable table thead tr th {
    border-right: 1px solid var(--table-border-color);
    border-bottom: 1px solid var(--table-border-color);
    color: var(--white);
    padding: 10px 15px;
    text-align: center;
}

.rolesPageTable table tbody tr td:last-child {
    border-right: 0px solid;
}

.rolesPageTable table thead tr th:last-child {
    border-right: 0px solid;
}

.rolesPageTable table tbody tr td {
    border-right: 1px solid var(--table-border-color);
    border-bottom: 1px solid var(--table-border-color);
}

.table-icons {
    border: 1px solid #93939347;
    padding: 2px;
    font-size: 16px !important;
    cursor: pointer;
    
}

.AddBtn {
    background: #5fbc61 !important;
    color: white !important;
    padding: 6px 40px !important;
    border-radius: 10px !important;
}

.modal-close-icon {
    position: absolute;
    top: 5%;
    right: 5%;
}

.form-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: rgba(213, 232, 202, 1);
    border-radius: 25px;
    /* height: 400px; */
}

/* view page design */

.viewCtr {
    background: #DDF1DE;
    margin: 0 auto;
    width: 35%;
}

.viewLeftSide {
    border-bottom: 1px solid #59595938;
    border-right: 1px solid #59595938;
    /* max-height: 100% !important; */
}

.viewLeftSide p {
    /* position: relative;
    left: -15px; */
    font-weight: bold;
    padding-left: 15px;
}

.viewRightSide {
    border-bottom: 1px solid #59595938;
    /* max-height: 100% !important; */
}

.viewRightSide p {
    padding-left: 15px;
}


.viewLeftSideAddress {
    padding-bottom: 64px !important;
}


/* .submitBtnn {
    height: 30px !important;
    width: 100px !important;
} */
.submitBtnn-task-form {
    height: 30px !important;
    min-width: 100px !important;
    margin-block: 0.8rem;
}

/* location lookup */
.pac-container {
    z-index: 99999 !important;
}

.formLeftList .MuiListItem-dense {
    padding-left: 0px;
}

.formDisableField input {
    border: 1px solid rgba(0, 0, 0, 0.42);
    /* background: white; */
    padding: 10px;
    font-size: 14px;
}

.formDisableSelectField .MuiInputBase-root {
    border: 1px solid rgba(0, 0, 0, 0.42);
    /* background: white; */
    padding: 10px;
    font-size: 14px;
}

.tableTablist .MuiTabs-scroller {
    background: #5FBC61;
    padding: 10px;
    border-radius: 10px;
}

.tableTablist .Mui-selected {
    color: #969696 !important;
    background: white;
    border-radius: 10px;
    padding: 0px 30px;
    min-height: 35px;
    margin-right: 20px;
}

.tableTablist button {
    color: #E5E7E6;
    background: transparent;
    border: 1px solid white;
    border-radius: 10px;
    padding: 0px 30px;
    min-height: 35px;
    margin-right: 20px;
}

/* taskview */

.taskview {
    border-radius: 5px;
    border: 1px solid #96E2FF;
}

.taskview-one {
    border-radius: 5px;
    border: 1px solid #96E2FF;
    height: 145px;
}

.taskview-font {
    font-size: 14px !important;

}

.taskform-divider {
    background-color: #96E2FF;
}

.taskPageTable {
    border: 1px solid #96E2FF;
    border-radius: 10px;
}

.taskgrid {
    padding-top: 30px !important;
}

.pac-target-input {
    padding: 8.5px 14px;
    height: 1.4375em;
    border: 1px solid #979797;
    border-radius: 5px;
    width: 92%;
    /* width: -moz-available; */
    background: transparent;
}

.address-employee .pac-target-input {
    padding: 8.5px 14px;
    height: 1.4375em;
    border: 1px solid #979797;
    border-radius: 5px;
    width: 95%;
    background: white;
    /* width: -moz-available; */

}

.pac-target-input:focus-visible {
    outline: none;
}

.indexBox {
    margin-top: 60px;
}

.MuiDrawer-paper {
    width: 380px !important;
    background: #C7DEE0 !important;
}

.calenderSearch .inputSearch svg {
    color: white;
}

.calenderSearch .inputSearch input {
    color: white;
}

.directorySelect fieldset legend {
    display: none;
}

/* add misc */
.misc-addicon {
    height: 18px;
    margin-top: 39px;
    cursor: pointer;
}

.delete-icons {
    border: 1px solid #93939347;
    padding: 2px;
    font-size: 16px !important;
    cursor: pointer;
    margin-top: 6px;
}

.form-control:hover {
    border: 1px solid black !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.class-1 fieldset {
    border-color: #d32f2f !important;
}

.class-2 fieldset {
    border-color: rgba(0, 0, 0, 0.23) !important;
}

.errorMsg {
    color: #d32f2f !important;
    margin-left: 14px !important;
}

.errorBorder {
    border: 1px solid #d32f2f !important;
}

.imageSelectError .uploader__container .uploader__placeholder {
    border: 1px solid #d32f2f !important;
}

.pac-target-input {
    padding: 8.5px 14px;
    height: 1.4375em;
    border: 1px solid #979797;
    border-radius: 5px;
    width: -webkit-fill-available;
    background: transparent !important;
}

.errorBorder {
    padding: 8.5px 14px;
    height: 1.4375em;
    border-radius: 5px;
    width: -webkit-fill-available;
    background: transparent !important;
}

.myprofilePara {
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 5px;
    padding: 10px;
    color: rgba(0, 0, 0, 0.38);
}

.countryCodeCtr .MuiInputBase-root {
    width: fit-content;
}

.autocompleteCtr .pac-target-input {
    padding: 8.5px 14px;
    height: 1.4375em;
    border: 1px solid #979797;
    border-radius: 5px;
    /* width: 95%; */
}

.invoiceDateRange {
    height: 35px;
    border: 1px solid #979797;
    border-radius: 5px;
    width: 210px;
    font-size: 14px !important;
    padding-left: 15px;
}

.privay-span {
    font-size: 24px;
    font-weight: 500;
    color: #000000
}

.privacy-policy {
    color: #565f74;
    font-size: 20px !important;
    font-weight: 500;
}

.indexBox {
    text-transform: capitalize;
}

.email-unset {
    text-transform: none !important;
}

.description-break {
    display: flex;
    flex-wrap: wrap;
    word-break: break-word;
}


.taskDateRange {
    height: 42px;
    border: 1px solid rgb(203, 203, 203);
    border-radius: 5px;
    width: 200px;
    font-size: 14px !important;
    padding-left: 15px;
}


.date-label {
    font-size: 0.8rem !important;
    margin-bottom: 5px;
}

.react-datepicker__close-icon::after {
    background-color: #216ba5 !important;
}