body {
    margin: 0;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Figtree", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    background: #ffffff;
    font-size: 14px;
}

* {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Figtree", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    /* font-family: 'Roboto', sans-serif !important; */
}

p,
label,
input,
button,
th,
td,
h1,
h2,
h3,
h4,
h5,
h6,
div,
legend,
span,
li {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Figtree", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

table {
    text-transform: capitalize;
}

.text-center-cls {
    text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

.w-100 {
    width: 100%;
}

.submitBtn {
    background: #049457;
    border-radius: 20px !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    text-transform: unset !important;
    height: 44px;
    padding: 0px 50px !important;
}

.authTextField .MuiInputBase-root {
    border-radius: 10px !important;
}

.authTextField fieldset {
    border-color: #9B9999;
}

/* verification */
.homepage {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100vh !important;
}

.check-icon {
    width: 70px !important;
    height: 70px !important;
    position: relative !important;
    border-radius: 50% !important;
    box-sizing: content-box !important;
    color: #4caf50 !important;
    border: 4px solid #4caf50 !important;
    /* margin-top: 25px !important; */
}

.fonts {
    font-size: 1.75rem !important;
    font-weight: 700 !important;
    margin-top: 15px !important;
}

.check-icon1 {
    width: 70px !important;
    height: 70px !important;
    position: relative !important;
    border-radius: 50% !important;
    box-sizing: content-box !important;
    color: red !important;
    border: 4px solid red !important;
    /* margin-top: 25px !important; */
}

.check-icon2 {
    padding: 4px !important;
    width: 70px !important;
    height: 70px !important;
    position: relative !important;
    border-radius: 50% !important;
    box-sizing: content-box !important;
    color: rgb(58, 58, 14) !important;
    border: 4px solid rgb(58, 58, 14) !important;
    /* margin-top: 25px !important; */
}

.loading-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 4px !important;
    width: 70px !important;
    height: 70px !important;
    position: relative !important;
}

/* add employee dropdown */
.addemployee-image {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
}

.addemployee-role {
    width: 100px
}

.addemployee-tablehead {
    overflow-x: auto;
}

.MuiDialog-container .MuiPaper-root {
    background: #D5E8CA !important;
}

.formTable thead tr th {
    padding: 10px;
    background: #5fbc61;
    color: white;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #1f8c3a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #1f8c3a;
}

.ps-menu-button:focus {
    background-color: #aed4c1 !important;
}

.ps-submenu-content.ps-open {
    background-color: #f0faf5 !important;
}

.ps-menu-label {
    color: #1f8c3a !important;
    font-weight: 600 !important;
}

.indexBox {
    padding: 20px 30px;
}

.form-popup {
    max-height: 400px;
    overflow: auto;
}